























import { Component, Vue } from 'vue-property-decorator'
import { ArrowLeftIcon } from 'vue-feather-icons'
import { ROUTES } from '@/router/routes'
import { binderModule } from '@/store/store-accessor'

@Component({
  name: 'AcknowledgementLayout',
  components: {
    ArrowLeftIcon
  }
})
export default class AcknowledgementLayout extends Vue {
  backToQuotes () {
    this.$router.push({
      name: ROUTES.QUOTES,
      params: { id: binderModule.application?.id as string }
    })
  }
}
