































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnswerMap, Question } from '@/models/questionnaire'
import { engine } from '@/engine'

@Component({
  name: 'AcknowledgementQuestion'
})
export default class AcknowledgementQuestion extends Vue {
  @Prop() readonly questions!: Question[]
  @Prop() readonly answers!: AnswerMap
  @Prop() readonly questionKey!: string
  checked = false
  hover = false

  get currentQuestion (): Question {
    const question = this.questions.find(question => question.id === this.questionKey)
    if (!question) {
      throw new Error(`question ${this.questionKey} not found`)
    }
    return question
  }

  get isVisible (): boolean {
    return this.currentQuestion.condition
      ? engine.evaluate(this.currentQuestion.condition, this.answers)
      : true
  }

  toggleChecked () {
    this.checked = !this.checked
    this.$nextTick(() => {
      this.$emit('submit-acknowledgements')
    })
  }
}
