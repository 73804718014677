












































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  binderModule,
  brokerageModule,
  quoteModule
} from '@/store/store-accessor'
import { Quote } from '@/models/quote'
import AcknowledgementLayout from '@/layouts/acknowledgement-layout.vue'
import SummaryCard from '@/components/quote/summary-card.vue'
import AgentHint from '@/components/quote/agent-hint.vue'
import AcknowledgementAccordion from '@/components/acknowledgements/index.vue'
import { Product } from '@/models/product'
import { AnswerMap } from '@/models/questionnaire'
import { ROUTES } from '../router/routes'
import { TokenNotFoundError } from '@/models/errors'
import LoadingIcon from '@/components/loading-icon/index.vue'

@Component({
  name: 'Acknowledgements',
  components: {
    AcknowledgementLayout,
    SummaryCard,
    AgentHint,
    LoadingIcon,
    AcknowledgementAccordion
  }
})
export default class Acknowledgements extends Vue {
  @Prop() id!: string;
  checkoutDisabled = true;
  loading = true;
  acknowledgementAnswers: AnswerMap = {};

  get selectedQuote (): Quote | undefined {
    if (!quoteModule.quoteSelections.length) return
    return quoteModule.quotesData.find(
      (quote) => quote.id === quoteModule.quoteSelections[0].quoteId
    )
  }

  get quoteProducts (): Product[] {
    // looking at index[0] of the quotes since MVP will only support one product
    return quoteModule.productList.filter(
      (product) => product.id === quoteModule.quotesData[0].productId
    )
  }

  async created () {
    window.scrollTo(0, 0)
    try {
      await binderModule.checkApplication()
      if (!this.selectedQuote) {
        this.loading = true
        await quoteModule.checkForPendingQuote({
          id: this.id
        })
      }
    } catch (e: any) {
      if (e instanceof TokenNotFoundError) {
        this.$router.push({ name: ROUTES.LINK_NOT_FOUND })
      } else {
        throw e
      }
    }

    document.title = brokerageModule.brokerage
      ? `${brokerageModule.brokerage.name} | ${this.$route?.meta?.title}`
      : this.$route?.meta?.title
  }

  async updateAcknowledgementsAnswers (form: HTMLFormElement) {
    for (const element of form.elements) {
      const checkbox = element as HTMLInputElement
      this.acknowledgementAnswers = {
        ...this.acknowledgementAnswers,
        [checkbox.id]: true
      }
    }

    // Send acknowledgements answers and expect true or false back based on api status
    this.checkoutDisabled = false
  }

  async sendAcknowledgementsAnswers (data: AnswerMap) {
    if (this.selectedQuote) {
      const res = await quoteModule.sendAcknowledgementsAnswers({
        quoteId: this.selectedQuote.id,
        data
      })
      return res
    }
    return false
  }

  async goToCheckout () {
    if (!this.checkoutDisabled) {
      const res = await this.sendAcknowledgementsAnswers(
        this.acknowledgementAnswers
      )
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: ROUTES.CHECKOUT,
            params: { id: this.id }
          })
        }, 500)
      } else {
        throw new Error('could not submit acknowledgements')
      }
    }
  }
}
