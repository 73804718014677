






























































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import {
  InstallmentFrequency,
  PaymentOptions,
  Quote,
  QuoteSelection
} from '@/models/quote'
import { Underwriter } from '@/models/underwriter'
import {
  binderModule,
  quoteModule,
  underwritersModule
} from '@/store/store-accessor'
import { Product, ProductType } from '@/models/product'
import { getProductName } from '@/helpers/products'
import { ChevronDownIcon, ChevronUpIcon, InfoIcon } from 'vue-feather-icons'
import { AnswerMap } from '@/models/questionnaire'
import AcknowledgementQuestion from '@/components/acknowledgements/question.vue'
import SideContainer from '@/components/side-container/index.vue'
import Hint from '@/components/hint/index.vue'
import DrawerDisplayMixin from '@/mixins/drawer-display-mixin'
import IconBase from '@/components/icon-library/icon-base.vue'
import AgentSupport from '@/components/icon-library/agent-support.vue'
import SectionWrapper from '@/components/section-wrapper/section-wrapper.vue'

@Component({
  name: 'AcknowledgementAccordion',
  components: {
    Hint,
    SideContainer,
    InfoIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    AcknowledgementQuestion,
    IconBase,
    AgentSupport,
    SectionWrapper
  }
})
export default class AcknowledgementAccordion extends Mixins(
  DrawerDisplayMixin
) {
  @Prop() selectedQuote!: Quote;

  get quoteSelection (): QuoteSelection | undefined {
    return quoteModule.quoteSelections.find(
      (quote) => quote.quoteId === this.selectedQuote.id
    )
  }

  get installmentData (): PaymentOptions | undefined {
    return this.selectedQuote.paymentOptions.find(
      (paymentOption) =>
        paymentOption.installmentFrequency ===
        this.quoteSelection?.installmentFrequency
    )
  }

  get installmentFrequency (): string {
    if (this.installmentData) {
      return this.installmentData.installmentFrequency ===
        InstallmentFrequency.Monthly
        ? 'month'
        : 'year'
    } else {
      return ''
    }
  }

  get answers (): AnswerMap {
    return binderModule.answers
  }

  get acknowledgmentsSet () {
    return quoteModule.acknowledgmentsQuestions
  }

  get productName () {
    return this.quoteProductType && getProductName(this.quoteProductType.slug)
  }

  get quoteProduct (): Product | undefined {
    return quoteModule.productList.find(
      (product) => product.id === this.selectedQuote.productId
    )
  }

  get quoteProductType (): ProductType | undefined {
    return quoteModule.productTypeList.find(
      (productType) => productType.id === this.quoteProduct?.productTypeId
    )
  }

  get underwriterLogo (): Underwriter | undefined {
    return underwritersModule.underwriters.find(
      (underwriter) => underwriter.id === this.quoteProduct?.underwriterId
    )
  }

  get acknowledgementPaymentValue () {
    return this.installmentData && this.installmentData.installmentPayment !== 0
      ? this.installmentData.installmentPayment
      : parseFloat(this.selectedQuote.policyPremium)
  }

  validateAcknowledgements () {
    const acknowledgmentsArr = []
    const acknowledgementForm = this.$refs
      .acknowledgmentsForm as HTMLFormElement
    for (const element of acknowledgementForm.elements) {
      const checkbox = element as HTMLInputElement
      acknowledgmentsArr.push(checkbox.checked)
    }

    const success = acknowledgmentsArr.every(
      (acknowledgement) => acknowledgement
    )
    if (success) {
      this.$emit('pass-acknowledgement', acknowledgementForm)
    } else {
      this.$emit('fail-acknowledgement')
    }
  }
}
